
<template>
  <v-dialog v-model="rejectDialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="id_document.status != 'rejected'"
        class="ma-2"
        color="error"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Reject
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Confirm rejection page</span>
      </v-card-title>
      <v-img
        :eager="true"
        :src="photo.photo_front"
      ></v-img>
      <v-img
        :eager="true"
        :src="photo.photo_back"
      ></v-img>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="rejectDialog = false">
          Close
        </v-btn>
        <v-btn color="red darken-1" text @click="submit">
          Confirm rejection
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import HTTP from "@/api/HTTP";
import store from "@/store";

export default {
  name: "IdDocumentReject",
  props: ['id_document'],

  data: function () {
    return {
      rejectDialog: false,
      photo : this.id_document.photo ? this.id_document.photo : {}
    };
  },
  components: {},

  methods: {
    submit() {
      if (this.id_document.id) {
        HTTP.put("auth/admin/id-documents/" + this.id_document.id, {
          status: "rejected",
        }).then((response) => {
          this.rejectDialog = false;
          store.commit("updateIdDocument", response.data);
        });
      }
    },
  }
};
</script>