<template>
                  <div class="flex-no-wrap justify-space-between">
                  <v-card-text>
                    <v-row class="mx-0">
                      <v-avatar size="60" class="profile">
                        <v-img :src="colada.customer.photo"></v-img>
                      </v-avatar>

                      <div class="mx-4">
                        <h4 class="mx-0">
                          {{ colada.customer.name }} {{ colada.customer.last_name }}
                        </h4>
                        <v-row
                          v-if="colada.colader.overall_score"
                          class="mx-0 my-0"
                        >
                          <v-icon size="16" color="secondary">mdi-star</v-icon>
                          <h4 class="font-weight-medium">
                            {{ colada.colader.overall_score }}
                          </h4>
                        </v-row>

                        <v-row class="mx-0 my-1">
                          <h2 class="secondary--text font-weight-bold">
                            {{ colada.delivery_time }}
                          </h2>
                          <div class="mx-1"></div>

                          <h2 class="secondary--text font-weight-bold">
                            {{ colada.weight }}
                          </h2>

                          <v-icon :size="icon_size" color="secondary">$kg</v-icon>
                          <div class="mx-1"></div>

                          <v-icon
                            v-if="colada.ironing"
                            :size="icon_size"
                            color="secondary"
                            >$iron</v-icon
                          >

                            <v-icon
                            v-if="colada.type == 'ironing_only'"
                            :size="icon_size"
                            color="secondary"
                            >$ironing_only</v-icon
                          >

                          <v-icon
                            v-if="colada.type == 'wash_n_dry' && colada.drying == 'hang_up'"
                            :size="icon_size"
                            color="secondary"
                            >$hang_up</v-icon
                          >


                          <v-icon
                            v-if="colada.shipping == 'door_to_door'"
                            :size="icon_size"
                            >$truck</v-icon
                          >

                          <v-icon
                            v-if="colada.underwear"
                            :size="icon_size"
                            color="secondary"
                            >$underwear</v-icon
                          >
                        </v-row>
                      </div>
                      <v-col>
                        <h2 class="text-right black--text font-weight-bold">
                          {{ colada.price_breakdown.total_colader.amount }}
                          {{ colada.price_breakdown.total_colader.currency }}
                        </h2>
                      </v-col>
                    </v-row>
                    <v-divider class="my-4"></v-divider>
                    <v-row>
                      <v-col class="col-4">
                        <p>Colada ID</p>

                        <p>User ID</p>

                        <p>Order number</p>
                        <p>Status</p>
                        <p>Start date</p>
                        <p>Pick up time</p>

                        <p>Timezone</p>
                        <p>Completion Timestamp</p>
                        <p v-if="colada.shipping == 'door_to_door'">Address</p>
                      </v-col>
                      <v-col class="text-right">
                        <p >{{ colada.id }}</p>

                        <p >{{ colada.customer.id }}</p>
                        <p >
                          {{ colada.order_number }}</p>
                        <p>  <ColadaCancel v-model="colada.status" :colada="colada" :key="'cancel' + colada.id"/>{{ colada.status }}</p>
                      <ColadaChangeStatus  v-model="colada"  :key="'change-status' + colada.id" />
                        <p >
                          {{ colada.start_date }} {{ colada.day_of_the_week }}
                        </p>
                        <p >{{ colada.pick_up_time }}</p>
                        <p>{{ colada.timezone }}</p>
                        <p >
                          {{ colada.completion_timestamp }}
                        </p>
                        <p
                          v-if="colada.shipping == 'door_to_door'"
                        >
                          {{ colada.customer.address.street_address }}
                          {{ colada.customer.address.street_address_2 }}
                          <br />
                          {{ colada.customer.address.postal_code }}
                          {{ colada.customer.address.city }}
                          <br />
                          {{ colada.customer.address.country }}
                        </p>
                      </v-col>
                    </v-row>

                    <v-rating
                      color="secondary"
                      readonly
                      background-color="secondary"
                      v-model="colada.overall_score"
                    ></v-rating>
                  </v-card-text>
                </div>
</template>

<script>

import ColadaCancel from "@/components/coladas/actions/ColadaCancel"
import ColadaChangeStatus from "@/components/coladas/actions/ColadaChangeStatus"


export default {
  name: "ColadaInfo",
  props: ["colada"],

  data: function () {
    return {
      icon_size : 25
    };
  },

  methods: {

  },
  components: {
    ColadaCancel,
    ColadaChangeStatus
  },
};
</script>