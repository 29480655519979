<template>
  <v-dialog v-model="deleteDialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="id_document.status != 'none'"
        class="ma-2"
        color="secondary"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Clear
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline"
        >Are you sure you want to clear this document?</v-card-title
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="deleteDialog = false">
          Close
        </v-btn>
        <v-btn color="primary darken-1" text @click="submit"> Confirm </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import HTTP from "@/api/HTTP";
import store from "@/store";

export default {
  name: "IdDocumentClear",
  props: ['id_document'],

  data: function () {
    return {
      deleteDialog: false,
    };
  },


  methods: {
    submit() {
      if (this.id_document.id) {
        HTTP.put("auth/admin/id-documents/" + this.id_document.id, {
        status: "none",
        }).then((response) => {
          this.deleteDialog = false;
            store.commit("updateIdDocument", response.data )
        });
      }
    },
  },
  components: {},
};
</script>