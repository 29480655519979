<template>
  <v-card v-if="payment_order">
    <v-card-title>
      <span class="headline">Colada Payment Info</span>
      <GeneratePaymentInvoice :payment_order="payment_order" :key="payment_order.id"/>
    </v-card-title>

    <v-card-text>
      <div class="flex-no-wrap justify-space-between">
        <v-row>
          <v-col class="col-4">
            <p>ID</p>
            <p>Colada ID</p>
            <p>Status</p>
            <p>Amount</p>
            <p>Payment method</p>
          </v-col>
          <v-col class="text-right" justify="end">
            <p>{{ payment_order.id }}</p>
            <p>{{ payment_order.item_id }}</p>

            <p>
              <PaymentRefund
                v-model="payment_order"
                :key="payment_order.id"
              />
              {{ payment_order.status }}
            </p>

            <p>
              {{ payment_order.amount.amount }}
              {{ payment_order.amount.currency }}
            </p>

            <p>{{ payment_order.payment_method }}</p>
          </v-col>
        </v-row>

        <v-card>
          <v-card-title>
            <span class="headline">Status history</span></v-card-title
          >
          <v-card-text>
            <v-list>
              <v-list-item
                v-for="(item, i) in payment_order.status_history"
                :key="i"
              >
                <v-row>
                  <v-col>
                    <p class="text-left">{{ item.status }}</p>
                  </v-col>
                  <v-col>
                    <p class="text-right">{{ item.timestamp }}</p>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import HTTP from "@/api/HTTP";
import PaymentRefund from "./actions/PaymentRefund";
import GeneratePaymentInvoice from "./actions/GeneratePaymentInvoice";

export default {
  name: "ColadaPaymentInfo",
  data () {
    return this.initialState();
  },
  components: {
    PaymentRefund,
    GeneratePaymentInvoice,
  },
  computed: {
    colada() {
      return store.state.colada;
    },
  },
  methods: {
    initialState() {
      return {
      user_info: undefined,
      payment_order: undefined,
      };
    },

    clear() {
      Object.assign(this.$data, this.initialState());
    },


    updatePaymentInfo() {
      if (this.colada) {
        console.log(this.colada.id);
        HTTP.get(
          "billing/users/" +
            this.colada.customer.id +
            "/colada/" +
            this.colada.id +
            "/payment-order"
        ).then((response) => {
          this.payment_order = response.data;
        });
      } else {
        this.payment_order = undefined;
      }
    },
  },
  watch: {
    colada() {
      this.clear()
      this.updatePaymentInfo();
    },
  },
  mounted() {},
};
</script>