<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <span class="headline">Profile Info</span>
    </v-expansion-panel-header>

    <v-expansion-panel-content v-if="user_info">
      <v-row>
        <v-col cols="3">
          <v-avatar size="100px" class="profile">
            <v-img :src="user_info.photo"></v-img>
          </v-avatar>
        </v-col>
        <v-col>
          <p class="text--primary">Name: {{ user_info.name }}</p>
          <p class="text--primary">Last Name: {{ user_info.lastname }}</p>
          <v-checkbox
            class="ma-0 pa-0"
            v-model="user_info.eligible_for_colader"
            label="Eligible for colader"
            @change="updateEligible"
          ></v-checkbox>

          <v-checkbox
            class="ma-0 pa-0"
            v-model="user_info.colader_trainer"
            label="Colader Trainer"
            @change="updateColaderTrainer"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import store from "../store";
import HTTP from "../api/HTTP";

export default {
  name: "UserProfile",
  data() {
    return this.initialState();
  },
  computed: {
    user_id() {
      return store.state.user_id;
    },
  },
  methods: {
    initialState() {
      return {
        user_info: {},
      };
    },

    clear() {
      Object.assign(this.$data, this.initialState());
    },

    updateFromApiResponse(user_info) {
      this.user_info = user_info;
    },

    updateColaderTrainer() {
      if (this.user_id) {
        HTTP.put("coladas/admin/users/" + this.user_id + "/colader_trainer", {
          colader_trainer: this.user_info.colader_trainer,
        }).then((response) => {
          const user_info = response.data;
          this.updateFromApiResponse(user_info);
        });
      }
    },

    updateEligible() {
      if (this.user_id) {
        HTTP.put(
          "coladas/admin/users/" + this.user_id + "/eligible_for_colader",
          {
            eligible_for_colader: this.user_info.eligible_for_colader,
          }
        ).then((response) => {
          const user_info = response.data;
          this.updateFromApiResponse(user_info);
        });
      }
    },
    updateUserProfile() {
      if (this.user_id) {
        HTTP.get("coladas/users/" + this.user_id).then((response) => {
          const user_info = response.data;
          this.updateFromApiResponse(user_info);
        });
      }
    },
  },
  watch: {
    user_id() {
      this.clear()
      this.updateUserProfile();
    },
  },
  mounted() {
    this.updateUserProfile();
  },
};
</script>