<template>
  <svg
    version="1.1"
    id="iron"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
  >
    <g>
      <path class="iron"
        d="M11.116,19.506c-2.681,0.001-5.362,0.005-8.043-0.001c-1.736-0.004-2.264-0.766-1.513-2.39
		c1.349-2.921,3.578-4.958,6.545-6.202c2.146-0.9,4.389-1.123,6.688-1.062c1.473,0.039,2.949,0.006,4.423,0.008
		c0.736,0.001,1.553,0.22,1.426-1.061c-0.103-1.041,0.094-2.167-1.465-2.169c-2.458-0.005-4.915-0.002-7.373-0.003
		c-0.313,0-0.629,0.022-0.937-0.017c-0.612-0.079-0.984-0.436-0.965-1.068c0.018-0.621,0.385-1.027,1.006-1.03
		c3.082-0.013,6.168-0.066,9.246,0.05c1.374,0.052,2.547,1.435,2.573,2.844c0.056,3.037,0.061,6.077,0.003,9.114
		c-0.032,1.701-1.409,2.958-3.17,2.979C16.746,19.529,13.931,19.505,11.116,19.506z M12.013,14.191
		c-0.064-0.595-0.386-1.036-0.977-1.078c-0.668-0.047-1.11,0.387-1.117,1.048c-0.007,0.602,0.42,1.002,1.016,1.02
		C11.544,15.199,11.879,14.779,12.013,14.191z M15.234,14.165c-0.086-0.588-0.405-1.031-1.004-1.053
		c-0.666-0.024-1.105,0.411-1.091,1.075c0.012,0.598,0.442,0.995,1.042,0.993C14.787,15.179,15.119,14.759,15.234,14.165z
		M17.367,13.083c-0.587,0.122-1.02,0.454-1.009,1.058c0.012,0.665,0.463,1.092,1.127,1.036c0.581-0.049,0.989-0.477,0.942-1.09
		C18.382,13.494,17.969,13.157,17.367,13.083z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IronIcon",
};
</script>


 <style type="text/css">

      .iron {
        fill: currentColor;

      }

    </style>