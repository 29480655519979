import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import ColadaIcon from "@/icons/ic_colada.svg";
import TruckIcon from "@/icons/TruckIcon.vue";
import IronIcon from "@/icons/IronIcon.vue";

import IroningOnlyIcon from "@/icons/IroningOnlyIcon.vue";
import HangUpIcon from "@/icons/HangUpIcon.vue";
import UnderwearIcon from "@/icons/UnderwearIcon.vue";
import KgIcon from "@/icons/KgIcon.vue";


Vue.use(Vuetify);

const MY_ICONS = {
  colada : ColadaIcon,
  truck: {
    component: TruckIcon
  },
  iron : {
    component: IronIcon
  },
  hang_up : {
    component : HangUpIcon
  },
  underwear : {
    component: UnderwearIcon
  },
  kg : {
    component: KgIcon
  },
  ironing_only : {
    component: IroningOnlyIcon
  },
  
}



export default new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#6E9DA0',
          secondary: '#8692C4',
        },
      },
    },
    icons: {
      iconfont: 'mdiSvg',
      values: MY_ICONS,
    },
  })
