<template>
  <v-container fluid>
   <ColadersMap/>
  </v-container>
</template>

<script>
// @ is an alias to /src


import ColadersMap from "@/components/maps/ColadersMap";

export default {
  name: "Maps",
  components: {
    ColadersMap
    
  },
};
</script>