<template>
  <v-card>
    <v-card-title>
      <span class="headline">Identity information</span>
    </v-card-title>
    <v-card-text>
      <p class="text--primary">Status: {{ status }}</p>
      <template v-if="id_document.info">
        <p class="text--primary">
          Document Number: {{ id_document.info.document_numbers }}
        </p>

        <p class="text--primary">Name: {{ id_document.info.first_name }}</p>
        <p class="text--primary">Last name: {{ id_document.info.last_name }}</p>
        <p class="text--primary">
          Birthday: {{ id_document.info.date_of_birth }}
        </p>
        <p class="text--primary">
          Expiration: {{ id_document.info.date_of_expiry }}
        </p>
        <p class="text--primary">
          Issuing country: {{ id_document.info.issuing_country }}
        </p>
        <p class="text--primary">
          Nationality: {{ id_document.info.nationality }}
        </p>
      </template>
      <IdDocumentActions
        :id_document="id_document"
        :key="id_document.id + status"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import HTTP from "@/api/HTTP";
import IdDocumentActions from "./IdDocumentActions";

export default {
  name: "IdDocumentInfo",
  data() {
    return this.initialState();
  },
  components: {
    IdDocumentActions,
  },
  computed: {
    user_id() {
      return store.state.user_id;
    },
    id_document_updated() {
      return store.state.id_document;
    },
  },
  methods: {
    initialState() {
      return {
        status: "",
        dialog: false,
        id_document: {},
      };
    },

    clear() {
      Object.assign(this.$data, this.initialState());
    },

    updateIdDocumentInfo() {
      if (this.user_id) {
        HTTP.get("auth/admin/id-documents/" + this.user_id).then((response) => {
          const id_document = response.data;
          this.status = id_document.status;
          this.id_document = id_document;
        });
      }
    },
  },
  watch: {
    user_id() {
      this.clear();
      this.updateIdDocumentInfo();
    },
    id_document_updated() {
      this.updateIdDocumentInfo();
    },
  },
  mounted() {
    this.updateIdDocumentInfo();
  },
};
</script>