<template>
  <v-container>
    <GoogleLogin
      :params="params"
      :renderParams="renderParams"
      :onSuccess="onSuccess"
      :logoutButton="false"
      >Google Login</GoogleLogin
    >
  </v-container>
</template>

<script>
import GoogleLogin from "vue-google-login";
import Vue from "vue";
import store from "../store";

export default {
  name: "Login",
  data() {
    return {
      // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
      params: {
        client_id:
          "724282402605-mrhrb61g60uf2fsmfo8t4l693ccldet3.apps.googleusercontent.com",
      },
      // only needed if you want to render the button with the google ui
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
    };
  },
  components: {
    GoogleLogin,
  },
  methods: {
    onSuccess(googleUser) {
      console.log(googleUser);

      // This only gets the user information: id, name, imageUrl and email
      console.log(googleUser.getBasicProfile());

      Vue.GoogleAuth.then((auth2) => {
        var google_id_token = auth2.currentUser.get().getAuthResponse()
          .id_token;
        console.log("google_token " + google_id_token);
        localStorage.setItem("google_token", google_id_token);
        store.commit("updateGoogleToken", google_id_token);
        this.$router.push({ name: "users" });
      });
    },
  },
};
</script>
