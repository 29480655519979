import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import { LoaderPlugin } from 'vue-google-login'
import VCountryRegionSelect from '@timbouc/vuetify-country-region-input'

import { Icon }  from 'leaflet'


Vue.config.productionTip = false

const CLIENT_ID = "724282402605-mrhrb61g60uf2fsmfo8t4l693ccldet3.apps.googleusercontent.com";

Vue.use(LoaderPlugin, {
  client_id: CLIENT_ID
});

Vue.use(VCountryRegionSelect)

import 'leaflet/dist/leaflet.css';

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

Vue.use(require('vue-moment'));


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')



import interceptor from './api/HTTPInterceptors.js';
interceptor();