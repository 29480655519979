<template>
  <svg
    version="1.0"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="92px"
    height="92px"
    viewBox="0 0 92 92"
    enable-background="new 0 0 92 92"
    xml:space="preserve"
	class="iron"
  >
    <g>
      <g>
        <path
          d="M3.475,19.877c28.35,0,56.7,0,85.05,0c2.257,0,2.257-3.5,0-3.5c-28.35,0-56.7,0-85.05,0
			C1.218,16.377,1.218,19.877,3.475,19.877L3.475,19.877z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M26.506,79.072c12.996,0,25.992,0,38.989,0c3.224,0,3.224-5,0-5c-12.996,0-25.992,0-38.989,0
			C23.281,74.072,23.281,79.072,26.506,79.072L26.506,79.072z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M67.994,75.876c0-9.964,0-19.928,0-29.892c0-3.224-5-3.224-5,0c0,9.964,0,19.928,0,29.892
			C62.994,79.1,67.994,79.1,67.994,75.876L67.994,75.876z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M29.006,76.572c0-10.173,0-20.345,0-30.518c0-3.224-5-3.224-5,0c0,10.173,0,20.345,0,30.518
			C24.006,79.796,29.006,79.796,29.006,76.572L29.006,76.572z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M24.506,44.216c-3.206,3.098-6.363,6.247-9.515,9.399c-2.282,2.282,1.254,5.817,3.536,3.536
			c3.153-3.152,6.309-6.301,9.515-9.399C30.364,45.508,26.824,41.976,24.506,44.216L24.506,44.216z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M18.062,53.267c-3.094-3.094-6.189-6.189-9.283-9.283c-2.282-2.282-5.817,1.254-3.536,3.536
			c3.094,3.094,6.189,6.189,9.283,9.283C16.808,59.084,20.344,55.549,18.062,53.267L18.062,53.267z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M8.895,47.171c6.343-6.343,12.687-12.687,19.03-19.03c-1.179-1.179-2.357-2.357-3.536-3.536
			c-2.669,2.669-5.338,5.338-8.007,8.007c-2.282,2.282,1.254,5.817,3.536,3.536c2.669-2.669,5.338-5.338,8.007-8.007
			c2.282-2.282-1.254-5.817-3.536-3.536c-6.343,6.343-12.687,12.687-19.03,19.03C3.078,45.918,6.613,49.453,8.895,47.171
			L8.895,47.171z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M63.959,47.751c3.206,3.098,6.363,6.247,9.515,9.399c2.282,2.282,5.817-1.254,3.536-3.536
			c-3.153-3.152-6.309-6.301-9.515-9.399C65.176,41.976,61.636,45.508,63.959,47.751L63.959,47.751z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M77.473,56.802c3.094-3.094,6.189-6.189,9.283-9.283c2.282-2.282-1.254-5.817-3.536-3.536
			c-3.094,3.094-6.189,6.189-9.283,9.283C71.656,55.549,75.192,59.084,77.473,56.802L77.473,56.802z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M86.64,43.636c-6.343-6.343-12.687-12.687-19.03-19.03c-2.282-2.282-5.817,1.254-3.536,3.536
			c2.669,2.669,5.338,5.338,8.007,8.007c2.282,2.282,5.817-1.254,3.536-3.536c-2.669-2.669-5.338-5.338-8.007-8.007
			c-1.179,1.179-2.357,2.357-3.536,3.536c6.343,6.343,12.687,12.687,19.03,19.03C85.387,49.453,88.922,45.918,86.64,43.636
			L86.64,43.636z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M26.506,28.873c12.996,0,25.992,0,38.989,0c3.224,0,3.224-5,0-5c-12.996,0-25.992,0-38.989,0
			C23.281,23.873,23.281,28.873,26.506,28.873L26.506,28.873z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M26.999,15.118c2.567,3.824,5.297,7.534,7.788,11.41c1.737,2.702,6.066,0.198,4.317-2.524
			c-2.49-3.875-5.22-7.586-7.788-11.41C29.529,9.933,25.196,12.433,26.999,15.118L26.999,15.118z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M60.795,12.594c-2.567,3.824-5.297,7.534-7.788,11.41c-1.749,2.721,2.581,5.226,4.317,2.524
			c2.49-3.875,5.22-7.586,7.788-11.41C66.915,12.433,62.582,9.933,60.795,12.594L60.795,12.594z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "HangUpIcon",
};
</script>


 <style type="text/css">
.hang_up {
  fill: currentColor;
}
</style>