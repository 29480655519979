
<template>
  <v-dialog v-model="showDialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="id_document.status != 'none'"
        class="ma-2"
        color="secondary"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Show
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Photos</span>
      </v-card-title>
      <v-img
       
        :eager="true"
        :src="photo.photo_front"
      ></v-img>
      <v-img
        :eager="true"
        :src="photo.photo_back"
      ></v-img>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "IdDocumentShow",
  props: ['id_document'],
  data: function () {
    return {
      showDialog: false,
            photo : this.id_document.photo ? this.id_document.photo : {}

    };
  },
  components: {},
  mounted () {
  },

  methods: {
  }
};
</script>