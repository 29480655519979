<template>
  <v-app>
    <link
      href="https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css"
      rel="stylesheet"
    />

    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-btn icon @click.stop="drawer = !drawer">
          <v-img
            alt="Colada Logo"
            class="shrink mr-0"
            contain
            src="@/assets/logo.png"
            width="70"
          />
        </v-btn>
        <router-link to="/">
          <v-img
            alt="Colada Name"
            class="hidden-sm-and-down"
            contain
            min-width="100"
            src="@/assets/name_white.png"
            width="150"
          />
        </router-link>
      </div>

      <v-spacer></v-spacer>

      <router-view name="search"></router-view>
      <v-spacer></v-spacer>
      <ToolbarUserInfo/>
    </v-app-bar>

    <v-main>
      <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-divider></v-divider>

        <v-list-item
          v-for="item in drawerRouters"
          :key="item.title"
          :to="item"
          link
        >
          <v-list-item-content>
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-navigation-drawer>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import ToolbarUserInfo from "@/components/ToolbarUserInfo";

export default {
  name: "App",

  data: () => ({
    drawer: null,
    drawerRouters: ["users", "id-documents", "maps", "coladers"],
  }),
  components: {
    ToolbarUserInfo
  }
};
</script>

