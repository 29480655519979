import router from '../router'
import HTTP from './HTTP'


export default function execute() {
  HTTP.interceptors.request.use(
    config => {
      const token = localStorage.getItem("colada_token");
      if (token) {
        config.headers.common["Authorization"] = token;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );


  HTTP.interceptors.response.use(function (response) {
    // Any status code within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    console.log(error);
    // Any status codes outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          //do something
          break;

        case 401:
          var google_id_token = localStorage.getItem('google_token');
          HTTP.post("auth/token", {
            grant_type: 'google',
            google_token: google_id_token
          })
            .then(function (response) {

              localStorage.setItem('colada_token', response.data.access_token);

            })
            .catch(function (error) {
              console.log(error);
              router.push('login')

            });

          break;
        case 403:
          router.replace({
            path: "/login",
            query: { redirect: router.currentRoute.fullPath }
          }); break; 

        case 502:
          setTimeout(() => {
            router.replace({
              path: "/login",
              query: {
                redirect: router.currentRoute.fullPath
              }
            });
          }, 1000);
      }
    }

    return Promise.reject(error);
  });

}