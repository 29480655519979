<template v-slot>
  <v-container>
    <v-switch
      v-model="employee"
      color="primary"
      label="Only employees"
    ></v-switch>

    <v-card>
      <v-card-title>
        <span class="headline"> Coladsers table</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :search="search"
        :single-expand="true"
        :expanded.sync="expanded"
        item-key="id"
        show-expand
        :items="colader_profiles"
        class="elevation-1"
      >
        <template v-slot:[`item.id`]="{ item }">
          <v-btn v-if="user_info(item.id)" @click="selectUserId(item.id)" icon>
            <v-avatar>
              <img
                :src="user_info(item.id).photo"
                :alt="user_info(item.id).name"
              />
            </v-avatar>
          </v-btn>
          <v-btn v-else color="secondary" @click="selectUserId(item.id)">{{
            item.id
          }}</v-btn>
        </template>

        <template v-slot:[`item.working_until`]="{ item }">
          <p>
            {{ item.working_until }}
            <span v-if="item.working_until">
              {{
                [item.working_until, "YYYY-MM-DD"] | moment("from", "now", true)
              }}
              remaining
            </span>
          </p>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <ColaderProfileInfo :key="item.id" :colader_profile="item" />
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import store from "@/store";
import router from "@/router";

import HTTP from "@/api/HTTP";
import ColaderProfileInfo from "@/components/coladers/ColaderProfileInfo";


export default {
  name: "ColadersTable",
  data: function () {
    return {
      expanded: [],
      search: "",
      colader_profiles: [],
      users_info_list: [],
      employee: false,
      headers: [
        {
          text: "User",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Name ", value: "name" },
        { text: "Working From", value: "working_from" },
        { text: "Working Until", value: "working_until" },
        { text: "City", value: "address.city" },
      ],
    };
  },
  components: {
    ColaderProfileInfo,
  },
  computed: {
    employee_query_param() {
      return this.employee ? this.employee : "";
    },
    user_info() {
      return (id) => {
        return this.users_info_list.find((item) => item.id === id);
      };
    },
  },
  methods: {
    getUserInfo(colader_id) {
      const index = this.users_info_list.findIndex(
        (item) => item.id === colader_id
      );

      return this.users_info_list[index];
    },
    getColaders() {
      HTTP.get(
        "/coladas/admin/coladers?size=50&employee=" + this.employee_query_param
      ).then((response) => {
        this.colader_profiles = response.data;
        this.colader_profile_ids = this.colader_profiles.map((profile) => {
          return profile.id;
        });

        this.colader_profiles.forEach((profile) => {
          profile.simple_schedule = profile.working_schedule
            .map((schedule) => {
              return schedule.start;
            })
            .sort();
        });

        HTTP.get(
          "/coladas/admin/users?user_ids=" + this.colader_profile_ids
        ).then((response) => {
          this.users_info_list = response.data;

          this.colader_profiles.forEach((profile) => {
            profile.name = this.user_info(profile.id).name;
          });
        });
      });
    },
    selectUserId(user_id) {
      console.log("Selected UserId", user_id);
      store.commit("updateUserId", user_id);
      router.push("users");
    },
  },
  watch: {
    employee() {
      this.getColaders();
    },
    expanded() {
      store.commit("updateColaderProfile", this.expanded[0]);
    },
  },

  mounted() {
    this.getColaders();
  },
};
</script>