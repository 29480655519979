<template>
  <v-dialog v-model="updateDialog" persistent max-width="1500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ma-2" color="primary" dark v-bind="attrs" v-on="on">
        Update
      </v-btn>
    </template>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <span class="headline">Id Document</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="updateForm" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="info.first_name"
                      label="Legal first name*"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="info.last_name"
                      label="Legal last name*"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="info.document_type"
                      :items="[
                        'driving_licence',
                        'national_identity_card',
                        'passport',
                        'work_permit',
                      ]"
                      label="DocumentType*"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="info.document_numbers"
                      label="Document Number*"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <BirthdayPicker v-model="info.date_of_birth" />
                    <ExpirationDatePicker v-model="info.date_of_expiry"/>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="info.gender"
                      :items="['male', 'female']"
                      label="Gender*"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-country-select
                      v-model="info.nationality"
                      label="Nationality*"
                      :country-name="countryName"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-country-select
                      v-model="info.issuing_country"
                      label="Issuing Country*"
                      :country-name="countryName"
                      autocomplete="true"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="updateDialog = false">
              Close
            </v-btn>
            <v-btn color="primary darken-1" text @click="submit"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="5">
        <v-card>
          <v-img :src="photo.photo_front"></v-img>
          <v-img :src="photo.photo_back"></v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
import store from "@/store";
import BirthdayPicker from "@/components/utils/BirthdayPicker";
import ExpirationDatePicker from "@/components/utils/ExpirationDatePicker";
import HTTP from "@/api/HTTP";

export default {
  name: "IdDocumentUpdate",
  props: ["id_document"],
  data: function () {
    return {
      valid: true,
      status: "",
      updateDialog: false,
      countryName : false,
      photo : this.id_document.photo ? this.id_document.photo : {}

    };
  },
  components: {
    BirthdayPicker,
    ExpirationDatePicker,
  },
  computed: {
    info() {
      return this.id_document.info ? this.id_document.info : {};
    },

  },
  methods: {
    submit() {
      if (this.id_document.id) {
        HTTP.put("auth/admin/id-documents/" + this.id_document.id, {
          info: this.info,
          status: "valid",
        }).then((response) => {
          this.updateDialog = false;
          store.commit("updateIdDocument", response.data);
        });
      }
    },
  }
};
</script>