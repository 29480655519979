export function  calculateWorkingScheduleSummary(working_schedule) {

    var summary = []

    var temporal = JSON.parse(JSON.stringify(working_schedule));

    temporal
      .sort(function (a, b) {
        return a.start.localeCompare(b.start);
      })
      .forEach((curr) => {

        if (
            summary.length != 0 &&
            summary[
                summary.length - 1
          ].end_exclusive === curr.start
        ) {
            summary[
                summary.length - 1
          ].end_exclusive = curr.end_exclusive;
        } else {
          let objCopy = JSON.parse(JSON.stringify(curr));
          summary.push(objCopy);
        }
      });

      return summary;
  }
