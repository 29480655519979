<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    enable-background="new 0 0 24 24"
    xml:space="preserve"
    class="underwear"
  >
    <line
      fill="none"
      stroke-miterlimit="10"
      x1="1.221"
      y1="6.723"
      x2="22.779"
      y2="6.723"
    />
    <path
      fill="none"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M22.779,4.084H1.221v5.907
	c0,0,7.916,0.836,7.916,9.925h5.768c0-8.903,7.874-9.853,7.874-9.853V4.084z"
    />
  </svg>
</template>

<script>
export default {
  name: "UnderwearIcon",
};
</script>


 <style type="text/css">
.underwear {
  stroke: currentColor;
}
</style>