<template>
  <v-dialog v-model="generatePaymentInvoiceDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn 
      v-if="payment_order.status == 'captured' && payment_order.amount.amount > 0"

      class="ma-2" color="secondary" dark v-bind="attrs" v-on="on" text>
        Generate invoice
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Generate invoice for 
           amount {{payment_order.amount.amount}} {{payment_order.amount.currency}}</span>
      </v-card-title>




<v-container  v-if="invoice_link">
    <v-card-actions>
      <v-btn 
       
        color="primary darken-1" text
        :href="invoice_link"
        target="_blank"
        @click="submit"> Download </v-btn>
    </v-card-actions>
</v-container>
      <v-container v-else>
                    <v-card-title>Enter customer billing info</v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="updateForm" v-model="valid" lazy-validation>
            <v-text-field
              v-model="billing_info.tin"
              label="CIF/NIF*"
              required
            ></v-text-field>

            <v-text-field
              v-model="billing_info.name"
              label="Name*"
              required
            ></v-text-field>
            <v-text-field
              v-model="address_info.street_address"
              label="Street Address*"
              required
            >
            </v-text-field>
            <v-text-field
              v-model="address_info.street_address_2"
              label="Street Address 2"
            ></v-text-field>
            <v-text-field
              v-model="address_info.postal_code"
              label="Postal Code*"
              required
            >
            </v-text-field>
            <v-text-field
              v-model="address_info.city"
              label="City*"
              required
            >
            </v-text-field>                        
            <v-text-field
              v-model="address_info.region"
              label="Region*"
              required
            >
            </v-text-field>                        
            <v-country-select
              v-model="address_info.country"
              label="Country*"
              :country-name="countryName"
            />
          </v-form>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="generatePaymentInvoiceDialog = false"
        >
          Close
        </v-btn>
        <v-btn color="primary darken-1" text
            :loading="loading"
            :disabled="loading"
        @click="submit"> Generate </v-btn>
      </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "@/store";

import HTTP from "@/api/HTTP";

export default {
  name: "GeneratePaymentInvoice",
  props: ["payment_order"],
  data: function () {
    return {
      valid: true,
      generatePaymentInvoiceDialog: false,
      countryName: false,
      billing_info: {},
      address_info: {},
      loading: false,
      invoice_link : undefined
    
    };
  },
  components: {},
  computed: {

    user_id() {
      return store.state.user_id;
    },
  },
  methods: {
    submit() {
      this.loading = true;
      if (this.user_id) {
        this.billing_info.address = this.address_info;
      
        HTTP.post(
          "billing/users/" + this.user_id +"/payment-orders/" + this.payment_order.id + "/invoice", {
            "recipient" : this.billing_info
          }
          
        ).then((response) => {
          this.invoice_link = response.data.invoice_link

        });
      }
    },
  },
};
</script>