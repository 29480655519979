<template>
  <v-dialog v-model="updateBillingInfoDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ma-2" color="primary" dark v-bind="attrs" v-on="on" text>
        Update
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Billing Info</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="updateForm" v-model="valid" lazy-validation>
            <v-text-field
              v-model="billing_info.tin"
              label="CIF/NIF*"
              required
            ></v-text-field>

            <v-text-field
              v-model="billing_info.name"
              label="Name*"
              required
            ></v-text-field>
            <v-text-field
              v-model="address_info.street_address"
              label="Street Address*"
              required
            >
            </v-text-field>
            <v-text-field
              v-model="address_info.street_address_2"
              label="Street Address 2"
            ></v-text-field>
            <v-text-field
              v-model="address_info.postal_code"
              label="Postal Code*"
              required
            >
            </v-text-field>
            <v-text-field
              v-model="address_info.city"
              label="City*"
              required
            >
            </v-text-field>                        
            <v-text-field
              v-model="address_info.region"
              label="Region*"
              required
            >
            </v-text-field>                        
            <v-country-select
              v-model="address_info.country"
              label="Country*"
              :country-name="countryName"
            />
          </v-form>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="updateBillingInfoDialog = false"
        >
          Close
        </v-btn>
        <v-btn color="primary darken-1" text @click="submit"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "@/store";

import HTTP from "@/api/HTTP";

export default {
  name: "BillingInfoUpdate",
  props: ["value"],
  data: function () {
    return {
      valid: true,
      updateBillingInfoDialog: false,
      countryName: false,
    
    };
  },
  components: {},
  computed: {
    address_info() {
      return this.value.address ? JSON.parse(JSON.stringify(this.value.address)) : {};
    },

    billing_info() {
      return this.value ? JSON.parse(JSON.stringify(this.value)) : {};
    },

    user_id() {
      return store.state.user_id;
    },
  },
  methods: {
    submit() {
      if (this.user_id) {
        this.billing_info.address = this.address_info;
        HTTP.put(
          "billing/users/" + this.user_id +"/invoice-configuration",
          this.billing_info
        ).then((response) => {
          this.updateBillingInfoDialog = false;
          this.$emit("input", response.data);

        });
      }
    },
  },
};
</script>