<template>
  <v-btn text text-transform="none">{{ logged_in_user }}</v-btn>
</template>

<script>
import store from "../store";
import Vue from "vue";

export default {
  data() {
    return {
        logged_in_user: ""
    };
  },
  computed: {
    google_login() {
      return store.state.google_id_token
    },
  },
  watch: {
      google_login() {
          this.updateUserInfo()
      }
  },
  mounted() {
      this.updateUserInfo()
  },
  methods: {
  updateUserInfo() {
    Vue.GoogleAuth.then((auth2) => {
       this.logged_in_user = auth2.currentUser.get().getBasicProfile().getName();
        
      });
  }
  }
};
</script>