<template>
  <v-autocomplete
    v-model="select"
    :loading="isLoading"
    :items="items"
    :search-input.sync="search"
    class="mx-4"
    flat
    hide-no-data
    hide-details
    label="Start typing email"
    solo-inverted
  >
          <template v-slot:append-outer>

            <v-icon
            :key="`icon-${isClearable}`"
            @click="isClearable = !isClearable"
            v-text="isClearable ? 'mdi-close' : 'mdi-refresh'"
            ></v-icon>
        </template>

  </v-autocomplete>

  
</template>

<script>
import store from "../store/index";
import HTTP from "../api/HTTP";
export default {
  name: "SearchInput",
  data: () => ({
    descriptionLimit: 60,
    items: [],
    isLoading: false,
    model: null,
    select: null,
    search: null,
    isClearable: true,
    bk : null
  }),
  methods: {

  },
  watch: {
    isClearable() {
        if(this.isClearable) {
          this.user_id = this.bk

        } else {
          this.bk = this.user_id
          this.user_id = null
        } 
    },
    select(val) {
      if (val.id === store.state.user_id) {
        this.user_id = null

      }
      this.user_id = val.id
      this.isClearable = true
    },
    search(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      HTTP.get("/auth/admin/credentials?email=" + val)
        .then(function (response) {
          return response.data;
        })
        .then((entries) => {
          this.items = entries.map((entry) => {
            const email = entry.email 
            return { text: email, value: entry};
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    user_id: {
      get() {
        return store.state.user_id;
      },
      set(value) {
        store.commit("updateUserId", value);
      },
    },
  },
  created() {

   
  }
};
</script>
