<template>
  <v-expansion-panel v-if="colader_profile">
    <v-expansion-panel-header>
      <span class="headline">Colader Profile Info</span>
    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <ColaderProfileInfo :key="colader_profile.id" :colader_profile="colader_profile"/>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import store from "@/store";
import HTTP from "@/api/HTTP";
import ColaderProfileInfo from "@/components/coladers/ColaderProfileInfo";


export default {
  name: "ColaderProfile",
  data () {
    return this.initialState()
  },
  components: {
    ColaderProfileInfo
  },
  computed: {
    user_id() {
      return store.state.user_id;
    },
  },
  methods: {
    initialState() {
      return {
      colader_profile: undefined,
      employee: undefined,
      };
    },

    clear() {
      Object.assign(this.$data, this.initialState());
    },

    getColaderProfile() {
      if (this.user_id) {
        HTTP.get("coladas/coladers/" + this.user_id, { validateStatus: false })
          .then((response) => {
            if (response.status == 200) {
              const profile = response.data;
              this.colader_profile = profile;
              this.colader_profile.simple_schedule = this.colader_profile.working_schedule
                .map((schedule) => {
                  return schedule.start;
                })
                .sort();
              this.getEmployee();

            } else {
              this.colader_profile = undefined;
            }
            store.commit("updateColaderProfile", this.colader_profile);
          })
          .catch(() => {
            console.log("undefined");
          });
      }
    },
    
  },
  watch: {
    user_id() {
      this.clear()
      this.getColaderProfile();
    },
  },
  mounted() {
    this.getColaderProfile();
    
  },
};
</script>