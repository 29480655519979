<template>
  <v-row>
    <IdDocumentUpdate :id_document="id_document"/>
    <IdDocumentClear :id_document="id_document" />
    <IdDocumentReject :id_document="id_document" />
    <IdDocumentShow :id_document="id_document" />
  </v-row>
</template>

<script>
import IdDocumentUpdate from "./actions/IdDocumentUpdate"
import IdDocumentReject from "./actions/IdDocumentReject"
import IdDocumentClear from "./actions/IdDocumentClear"
import IdDocumentShow from "./actions/IdDocumentShow"

export default {
  name: "IdDocumentActions",
  props: ['id_document'],
  data: function () {
    return {
      
    };
  },
  components: {
    IdDocumentUpdate,
    IdDocumentReject,
    IdDocumentClear,
    IdDocumentShow
  },

};
</script>