<template>
    <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
    >
    <template v-slot:activator="{ on, attrs }">
        <v-text-field
        v-model="date"
        prepend-icon="mdi-calendar"
        readonly
        clearable
        v-bind="attrs"
         @input="save"
      
        v-on="on"
        ></v-text-field>
    </template>
    <v-date-picker
        v-model="date"
         show-adjacent-months
        :first-day-of-week="1"
        @input="menu = false"
         @change="save"
    ></v-date-picker>
    </v-menu>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
    default: ""
    },
  },
  data: () => ({
      menu: false,
      date: undefined
  }),
  watch: {


  },
  created() {
      this.date = this.value ? this.value : ""
  },

  methods: {
    save() {
     this.$emit("input", this.date);
      this.$emit("change");
    },
  },
};
</script>