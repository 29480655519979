<template>
  <v-container fluid>
    <v-row style="height: 150px" dense>
      <v-col>
          <CredentialsInfo />
        <v-divider ></v-divider>

          <IdDocumentInfo />
          <v-divider></v-divider>
                  <BillingInfo/>


      </v-col>
      <v-col>
          <v-expansion-panels :multiple="true" :value="[0,2]">

        <UserProfile />
        <v-divider ></v-divider>
        <ColaderProfile />
         <v-divider ></v-divider>

            <Coladas/>
          </v-expansion-panels>
      </v-col>
      <v-col>

        <ColadaPaymentInfo/>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import UserProfile from "../components/UserProfile";
import CredentialsInfo from "../components/CredentialsInfo";
import Coladas from "../components/Coladas";
import ColaderProfile from "../components/ColaderProfile";

import IdDocumentInfo from "../components/id-documents/IdDocumentInfo";
import ColadaPaymentInfo from "@/components/payment-orders/ColadaPaymentInfo"
import BillingInfo from "@/components/BillingInfo"

export default {
  name: "Users",
  components: {
    UserProfile,
    CredentialsInfo,
    IdDocumentInfo,
    Coladas,
    ColadaPaymentInfo,
    ColaderProfile,
    BillingInfo
    
  },
};
</script>
