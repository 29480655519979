<template>
    <v-list-item >
    <v-list-item-content>
        <v-list-item-title>{{title}} </v-list-item-title>
        <v-list-item-subtitle v-if="subtitle">
            {{subtitle}}
        </v-list-item-subtitle>
                <slot></slot>
    </v-list-item-content>

                <v-list-item-action>
                    <slot name="action"></slot>
                </v-list-item-action>

    </v-list-item>
</template>

<script>

export default {
  name: "ColaderProfileItem",
  props: ["title", "subtitle"],

  data: function () {
    return {

    };
  },
  methods: {

  },
  components: {},
};
</script>