<template>
  <v-dialog v-model="coladerScheduleSelector" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon color="secondary">mdi-square-edit-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">
        Configure schedule for {{ day.day_of_week }}
      </v-card-title>
      <v-list-item
        v-for="(schedule, i) in selectableScheduleList"
        :key="i"
        link
      >
        <v-list-item-title
          >{{ schedule.start }} - {{ schedule.end_exclusive }}
        </v-list-item-title>
        <v-checkbox
          v-model="day.working_schedule"
          :value="schedule"
          multiple
          color="secondary"
          @change="$emit('day-change')"
        ></v-checkbox>
      </v-list-item>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "ColadersScheduleSelector",
  props: ["value", "day"],

  data: function () {
    return {
      coladerScheduleSelector: false,


    };
  },
  watch: {
    coladerScheduleSelector() {
      if (this.coladerScheduleSelector == false) {
        this.$emit("close")
      }
    },
  },
  computed: {
      selectableScheduleList() {
          return ["9","10","11","12","13","14","15","16","17","18","19","20","21"].map((time) => {
              return this.buildSchedule(time)
          })
      }
  },



  methods: {
    buildSchedule(start) {
        return {
            "start" : start.padStart(2, "0") + ":00:00",
            "end_exclusive" : String(+start + 1).padStart(2, "0") + ":00:00"
        }
    }
  },
  components: {},
};
</script>