var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-switch',{attrs:{"color":"primary","label":"Only employees"},model:{value:(_vm.employee),callback:function ($$v) {_vm.employee=$$v},expression:"employee"}}),_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" Coladsers table")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"search":_vm.search,"single-expand":true,"expanded":_vm.expanded,"item-key":"id","show-expand":"","items":_vm.colader_profiles},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [(_vm.user_info(item.id))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.selectUserId(item.id)}}},[_c('v-avatar',[_c('img',{attrs:{"src":_vm.user_info(item.id).photo,"alt":_vm.user_info(item.id).name}})])],1):_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.selectUserId(item.id)}}},[_vm._v(_vm._s(item.id))])]}},{key:"item.working_until",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(item.working_until)+" "),(item.working_until)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")([item.working_until, "YYYY-MM-DD"],"from", "now", true))+" remaining ")]):_vm._e()])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('ColaderProfileInfo',{key:item.id,attrs:{"colader_profile":item}})],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }