<template>
  <v-row justify="center">
    <v-dialog v-model="show_calendar" width="1300">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon medium color="primary" dark v-bind="attrs" v-on="on">
          <v-icon> mdi-calendar </v-icon>
        </v-btn>
      </template>
      <v-container fluid>
        <v-row dense>
          <v-col>
            <v-card tile>
              <v-card-title>
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setToday"
                >
                  Today
                </v-btn>
                <v-btn
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="$refs.calendar.prev()"
                >
                  <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="$refs.calendar.next()"
                >
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-calendar
                ref="calendar"
                v-model="value"
                color="primary"
                :weekdays="weekday"
                :type="type"
                :events="events"
                :event-overlap-mode="mode"
                :event-overlap-threshold="30"
                :event-color="getEventColor"
                @change="getCalendarInfo"
                @click:date="showDayEdit"
              ></v-calendar>
            </v-card>
          </v-col>
          <ColaderCalendarDay v-if="calendar_day" :key="calendar_day.day" v-model="calendar_day" 
          
          :colader_profile="colader_profile"
           v-on:day-change="getCalendarInfo"  />
        </v-row>
      </v-container>
    </v-dialog>
  </v-row>
</template>

<script>
import HTTP from "@/api/HTTP";

import ColaderCalendarDay from "@/components/coladers/ColaderCalendarDay";

export default {
  name: "ColaderCalendar",
  props: ["colader_profile"],
  components: {
    ColaderCalendarDay,
  },

  data() {
    return {
      show_calendar: false,
      selected_day: undefined,
      calendar_day: undefined,
      calendar_days: [],
      calendar_start_day : undefined,
      calendar_selected_day : undefined,
      value: "",
      today: "",
      type: "month",
      weekday: [1, 2, 3, 4, 5, 6, 0],
      mode: "stack",
      events: [],
      colors: ["error", "warning", "secondary"],
    };
  },
  computed: {

  },
  watch: {

  },
  mounted() {},
  methods: {
    setToday() {
      this.value = "";
      console.log(this.today)
      this.calculateCalendarDay(this.today)
  
    },
    getEventColor(event) {
      return event.color;
    },
    calculateCalendarDay(selectedDay) {
        this.calendar_day = this.calendar_days.find((day) => {

        return day.day === selectedDay
      })
    },
    showDayEdit(dayFromCalendar) {
    
    this.calendar_selected_day = dayFromCalendar.date

    this.calculateCalendarDay(this.calendar_selected_day)

    },

    getCalendarInfo(calendar_change) {

      if (calendar_change) {
        this.calendar_start_day = calendar_change.start.date
      }

      if (this.colader_profile.id) {
        HTTP.get(
          "coladas/coladers/" +
            this.colader_profile.id +
            "/working-calendar?from=" +
            this.calendar_start_day
        )
          .then((response) => {
            console.log(response.data);
            this.calendar_days = response.data.days;
            this.today = response.data.today;

            if (this.calendar_selected_day) {
              this.calculateCalendarDay(this.calendar_selected_day)
            }

            this.events = [];

            this.calendar_days.forEach((day) => {
              if (day.working == false) {
                this.events.push({
                  name: "not working",
                  start: day.day,
                  end: day.day,
                  color: this.colors[0],
                  timed: false,
                });
              }

              if (day.has_rules == true) {
                this.events.push({
                  name: "has rule",
                  start: day.day,
                  end: day.day,
                  color: this.colors[1],
                  timed: false,
                });
              }

              if (day.has_coladas == true) {
                this.events.push({
                  name: "has coladas",
                  start: day.day,
                  end: day.day,
                  color: this.colors[2],
                  timed: false,
                });
              }
            });
          })
          .catch(() => {});
      }
    },
  },
};
</script>
