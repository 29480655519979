<template>
  <v-card>
    <v-card-title>
      <span class="headline">Billing Info</span>
      <BillingInfoUpdate v-model="billing_info" />
      <BillingInfoDelete v-if="billing_info.tin"  v-model="billing_info"/>
    </v-card-title>

    <v-card-text v-if="billing_info.tin">

          <h4 >{{ billing_info.tin }}<br/>
          {{ billing_info.name }}</h4>
            <h4>
              {{ billing_info.address.street_address }}
              {{ billing_info.address.street_address_2 }}
            </h4>
            <p>
              {{ billing_info.address.postal_code }}
              {{ billing_info.address.region }} {{ billing_info.address.city
              }}<br />{{ billing_info.address.country }}
            </p>

    </v-card-text>
  </v-card>
</template>

<script>
import store from "../store";
import HTTP from "../api/HTTP";

import BillingInfoUpdate from "@/components/billing/BillingInfoUpdate";
import BillingInfoDelete from "@/components/billing/BillingInfoDelete";

export default {
  name: "BillingInfo",
  data () {
    return this.initialState()
  },
  components: {
    BillingInfoUpdate,
    BillingInfoDelete,
  },
  computed: {
    user_id() {
      return store.state.user_id;
    },
  },
  methods: {
    initialState() {
      return {
      billing_info: {},

      };
    },

    clear() {
        Object.assign(this.$data, this.initialState());
    },

    getBillingInfo() {
      if (this.user_id) {
        HTTP.get(
          "billing/users/" + this.user_id + "/invoice-configuration"
        ).then((response) => {
          if (response.status == 200) {
            this.billing_info = response.data;
          } else {
            this.billing_info = {};
          }
        }).catch(() => this.billing_info = {} );
      }
    },
  },
  watch: {
    user_id() {
      this.clear()
      this.getBillingInfo();
    },
  },
  mounted() {
    this.getBillingInfo();
  },
};
</script>