<template>
  <v-card>
    <v-card-title>
      <span class="headline">Credentials information</span>
    </v-card-title>
    <v-card-text>
      <p class="text--primary">Internal ID: {{ id }}</p>
      <p class="text--primary">Email: {{ email }}</p>
      <p class="text--primary">Phone number: {{ phoneNumber }}</p>
      <p class="text--primary">GoogleId: {{ googleId }}</p>
      <p class="text--primary">FacebookId: {{ facebookId }}</p>
      <v-divider vertical></v-divider>

      <v-chip
        class="ma-2"
        color="primary"
        text-color="white"

      >
        <v-avatar left>
          <v-icon v-if="phoneVerified" >mdi-checkbox-marked-circle</v-icon>
        </v-avatar>
        Phone
      </v-chip>


      <v-chip
        class="ma-2"
        color="primary"
        text-color="white"

      >
        <v-avatar left>
          <v-icon v-if="emailVerified" >mdi-checkbox-marked-circle</v-icon>
        </v-avatar>
        Email
      </v-chip>

    </v-card-text>
  </v-card>
</template>

<script>
import store from "../store";
import HTTP from "../api/HTTP";

export default {
  name: "CredentialsInfo",
  data() {
     return this.initialState();
  },
  computed: {
    user_id() {
      return store.state.user_id;
    },
  },
  
  methods: {
    initialState() {
      return {
        id : "",
        email: "",
        phoneNumber: "",
        googleId: "",
        facebookId: "",
        phoneVerified: false,
        emailVerified: false,
      };
    },

    clear() {
        Object.assign(this.$data, this.initialState());
    },
    updateCredentialsInfo() {

      if(this.user_id) {
        HTTP.get("auth/credentials/" + this.user_id).then((response) => {
          const credential = response.data;
          this.id = credential.id
          this.email = credential.email;
          this.phoneNumber = credential.phone_number;
          this.googleId = credential.google_id;
          this.facebookId = credential.facebook_id;
          this.phoneVerified = credential.verifications.phone;
          this.emailVerified = credential.verifications.email;
        });
      }
    },
  },
  watch: {
    user_id() {
      this.clear();
      this.updateCredentialsInfo();
    },
  },
  created() {
    console.log("Credntials created" + this.user_id)
    if (this.user_id) {
      this.updateCredentialsInfo();
    }
  }
};
</script>