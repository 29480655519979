import Vue from 'vue'
import VueRouter from 'vue-router'
import Users from '@/views/Users.vue'
import Login from '@/views/Login.vue'
import Maps from '@/views/Maps.vue'
import Coladers from '@/views/Coladers.vue'

import SearchInput from '@/components/SearchInput.vue'
import IdDocuments from '@/views/IdDocuments.vue'
import IdDocumentSearchInput from '@/components/id-documents/IdDocumentSearchInput.vue'

Vue.use(VueRouter)

function guardMyroute(to, from, next)
{
 var isAuthenticated= false;
//this is just an example. You will have to find a better or 
// centralised way to handle you localstorage data handling 
if(localStorage.getItem('google_token'))
  isAuthenticated = true;
 else
  isAuthenticated= false; if(isAuthenticated) 
 {
  next(); // allow to enter route
 } 
 else
 {
  next('/login'); // go to '/login';
 }
}


const routes = [
  {
    path: '/',
    alias: ['/users'],
    name: 'users',
    beforeEnter : guardMyroute,
    components: {
      default: Users,
      search: SearchInput
    }
  },
  {
    path: '/id-documents',
    name: 'id-documents',
    beforeEnter : guardMyroute,
    components: {
      default: IdDocuments,
      search: IdDocumentSearchInput
    }
  },
  {
    path: '/maps',
    name: 'maps',
    beforeEnter : guardMyroute,
    components: {
      default :Maps,
    }
  },
  {
    path: '/coladers',
    name: 'coladers',
    beforeEnter : guardMyroute,
    components: {
      default :Coladers,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
