<template>
  <v-menu
    ref="expiration_date_menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date_of_expiry"
        label="Expiration date*"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      ref="expiration_picker_picker"
      v-model="date_of_expiry"
      :min="new Date().toISOString().substr(0, 10)"
      :show-current="new Date().toISOString().substr(0, 10)"
      @change="save"
    ></v-date-picker>
  </v-menu>
</template>


<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
            default: ""

    },
  },

  data: () => ({
    date_of_expiry: "",
    expiration_date_menu: false,
  }),
  watch: {
    expiration_date_menu(val) {
      val &&
        setTimeout(
          () => (this.$refs.expiration_picker_picker.activePicker = "YEAR")
        );
    },
  },
  mounted() {
    this.date_of_expiry = this.value;
  },
  methods: {
    save(date) {
      this.$refs.expiration_date_menu.save(date);
      this.$emit("input", date);
    },
  },
};
</script>