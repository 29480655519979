
<template>
  <v-dialog v-model="billingInfoDeleteDialoge" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ma-2"
        color="error"
        dark
        v-bind="attrs"
        v-on="on"
        text
      >
        Delete
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Billing info delete </span>
      </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="billingInfoDeleteDialoge = false">
          Close
        </v-btn>
        <v-btn color="red darken-1" text @click="submit">
          Confirm deletion
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import HTTP from "@/api/HTTP";
import store from "@/store";

export default {
  name: "BillingInfoDelete",
  props: ["value"],

  data: function () {
    return {
      billingInfoDeleteDialoge: false,
    };
  },
    computed: {
    user_id() {
      return store.state.user_id;
    },
  },
  components: {},

  methods: {
    submit() {
      if (this.user_id) {
        HTTP.delete("billing/users/" + this.user_id + "/invoice-configuration").then(() => {
          this.billingInfoDeleteDialoge = false;
          this.$emit("input", {});

        });
      }
    },
  }
};
</script>