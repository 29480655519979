<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <span class="headline">Coladas</span>
    </v-expansion-panel-header>

    <v-expansion-panel-content >
      <v-tabs fixed-tabs v-model="tab">
        <v-tab>Customer</v-tab>
        <v-tab>Colader</v-tab>
      </v-tabs>
      <v-list style="height: calc(100vh - 450px); overflow-y: scroll;">
        <v-list-item-group v-model="selected_colada" color="primary">
              <v-list-item v-for="(item, i) in coladas" :key="i" >
                <div class="flex-no-wrap justify-space-between">
                  <v-card-text>
                    <v-row class="mx-0">
                      <v-avatar size="60" class="profile">
                        <v-img :src="item.profile.photo"></v-img>
                      </v-avatar>

                      <div class="mx-4">
                        <h4 class="mx-0">
                          {{ item.profile.name }} {{ item.profile.last_name }}
                        </h4>
                        <v-row
                          v-if="item.profile.overall_score"
                          class="mx-0 my-0"
                        >
                          <v-icon size="16" color="secondary">mdi-star</v-icon>
                          <h4 class="font-weight-medium">
                            {{ item.profile.overall_score }}
                          </h4>
                        </v-row>

                        <v-row class="mx-0 my-1">
                          <h2 class="secondary--text font-weight-bold">
                            {{ item.delivery_time }}
                          </h2>

                          <h2 class="secondary--text font-weight-bold ml-2">
                            {{ item.weight }}
                          </h2>
                          <v-icon size="25" color="secondary">$kg</v-icon>

                          <v-icon
                            v-if="item.type == 'wash_n_dry' && item.ironing"
                            size="25"
                            class="ml-2"
                            color="secondary"
                            >$iron</v-icon
                          >


                            <v-icon
                            v-if="item.type == 'ironing_only'"
                            size="25"
                            class="ml-2"

                            color="secondary"
                            >$ironing_only</v-icon
                          >

                          <v-icon
                            v-if="item.type == 'wash_n_dry' && item.drying == 'hang_up'"
                            size="25"
                            class="ml-2"
                            color="secondary"
                            >$hang_up</v-icon
                          >



                          <v-icon
                            v-if="item.shipping == 'door_to_door'"
                            color="secondary"
                            class="ml-2"
                            size="25"
                            >$truck</v-icon
                          >

                          <v-icon
                            v-if="item.underwear"
                            size="25"
                            class="ml-2"

                            color="secondary"
                            >$underwear</v-icon
                          >
                        </v-row>
                      </div>
                      <v-col>
                        <h2 class="text-right black--text font-weight-bold">
                          {{ item.show_price.amount }}
                          {{ item.show_price.currency }}
                        </h2>
                      </v-col>
                    </v-row>
                    <v-divider class="my-4"></v-divider>
                    <v-row>
                      <v-col class="col-4">
                        <p>Colada ID</p>

                        <p>User ID</p>

                        <p>Order number</p>
                        <p>Status</p>
                        <p>Start date</p>
                        <p>Pick up time</p>

                        <p>Timezone</p>
                        <p>Completion Timestamp</p>
                        <p v-if="item.shipping == 'door_to_door'">Address</p>
                      </v-col>
                      <v-col class="text-right">
                        <p >{{ item.id }}</p>

                        <p >{{ item.profile.id }}</p>
                        <p >
                          {{ item.order_number }}</p>
                        <p>   <ColadaChangeStatus  v-model="item.status" :colada="item"  :key="'change-status' + item.id" />{{ item.status }}
                             </p>


                        <p >
                          {{ item.start_date }} {{ item.day_of_the_week }}
                        </p>
                        <p >{{ item.pick_up_time }}</p>
                        <p>{{ item.timezone }}</p>
                        <p >
                          {{ item.completion_timestamp }}
                        </p>
                        <p
                          v-if="item.shipping == 'door_to_door'"
                        >
                          {{ item.customer.address.street_address }}
                          {{ item.customer.address.street_address_2 }}
                          <br />
                          {{ item.customer.address.postal_code }}
                          {{ item.customer.address.city }}
                          <br />
                          {{ item.customer.address.country }}
                        </p>
                      </v-col>
                    </v-row>

                    <v-rating
                      color="secondary"
                      readonly
                      background-color="secondary"
                      v-model="item.overall_score"
                    ></v-rating>
                  </v-card-text>
                </div>
              </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import store from "@/store";
import HTTP from "@/api/HTTP";
// import ColadaCancel from "@/components/coladas/actions/ColadaCancel"
import ColadaChangeStatus from "@/components/coladas/actions/ColadaChangeStatus"

export default {
  name: "Coladas",

  data () {
    return this.initialState();
  },
  components: {
    // ColadaCancel,
    ColadaChangeStatus
  },
  computed: {
    user_id() {
      return store.state.user_id;
    },
  },
  methods: {
    initialState() {
      return {
      coladas: [],
      tab_header: ["customer", "colader"],
      tab: 0,
      selected_colada: undefined,

      };
    },

    clear() {

      store.commit("updateColada", null);
        Object.assign(this.$data, this.initialState());
    },

    updateColadas() {
      if (this.user_id) {
        var selectedTab = this.tab;
        HTTP.get("coladas/users/" + this.user_id + "/coladas", {
          headers: {
            "Colada-User-Role": this.tab_header[selectedTab],
          },
        }).then((response) => {
          var days_of_the_weeks_list = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          this.coladas = response.data;
          this.coladas.forEach(function (item) {
            var d = new Date(item.start_date);
            item.day_of_the_week = days_of_the_weeks_list[d.getDay()];
            if (selectedTab == 0) {
              item.profile = item.colader;
              item.show_price = item.price_breakdown.total_customer;
            } else {
              item.profile = item.customer;
              item.show_price = item.price_breakdown.total_colader;
            }
          });
        });
      }
    },
  },
  watch: {
    user_id() {
      console.log("UserID changed" + this.user_id)
      this.clear()
      this.updateColadas();
    },
    tab() {
      this.updateColadas();
    },
    selected_colada() {
      var colada;
      console.log(this.selected_colada);
      if (this.selected_colada != undefined) {
      colada = this.coladas[this.selected_colada];
          console.log(colada.id);

      } else {
        colada = undefined;
      }
      store.commit("updateColada", colada);
    },
  },
  mounted() {
    this.updateColadas();
  },
};
</script>

<style scoped>
.border {
  border: 2px dashed;
}
</style>