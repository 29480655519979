<template>
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="128px"
    height="128px"
    viewBox="0 0 128 128"
    enable-background="new 0 0 128 128"
    xml:space="preserve"
    class="kg"
  >
    <g>
      <rect x="3.423" y="12.912" fill="none" width="119.562" height="108.175" />
      <path
        d="M32.174,59.396L24.557,67.6v20.312H9.908V16.818h14.648v32.227l6.445-8.838l18.115-23.389h18.018L41.891,48.41
		l25.977,39.502H50.436L32.174,59.396z"
      />
      <path
        d="M69.137,61.105c0-8.105,1.929-14.632,5.786-19.58c3.857-4.948,9.058-7.422,15.601-7.422c5.794,0,10.303,1.986,13.525,5.957
		l0.586-4.98h12.793v51.074c0,4.622-1.05,8.643-3.149,12.061s-5.054,6.021-8.862,7.812c-3.809,1.79-8.269,2.686-13.379,2.686
		c-3.874,0-7.65-0.773-11.328-2.319c-3.679-1.547-6.462-3.54-8.35-5.981l6.25-8.594c3.516,3.938,7.779,5.908,12.793,5.908
		c3.743,0,6.656-1.001,8.74-3.003c2.083-2.002,3.125-4.843,3.125-8.521v-2.832c-3.256,3.679-7.536,5.518-12.842,5.518
		c-6.348,0-11.483-2.481-15.405-7.446c-3.923-4.964-5.884-11.548-5.884-19.751V61.105z M83.248,62.109
		c0,4.808,0.96,8.576,2.881,11.307c1.92,2.73,4.557,4.096,7.91,4.096c4.297,0,7.373-1.618,9.229-4.856V50.336
		c-1.889-3.237-4.932-4.856-9.131-4.856c-3.386,0-6.047,1.39-7.983,4.169C84.216,52.43,83.248,56.583,83.248,62.109z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "KgIcon",
};
</script>


 <style type="text/css">
.kg {
  fill: currentColor;
}
</style>