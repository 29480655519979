import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user_id : "",
    id_document_search_status: "",
    id_document: {},
    google_id_token: "",
    colada : {},
    payment_order : {},
    colader_profile: {}
  },
  mutations: {
    updateUserId(state, user_id) {
      state.user_id = user_id;
      console.log("MUT Username:" + user_id);

    },
    updateIdDocumentSearchStatus(state, status) {
      state.id_document_search_status = status
    },
    updateIdDocument(state, id_document) {
      state.id_document = id_document
    },
    updateGoogleToken(state, google_id_token) {
      state.google_id_token = google_id_token
    },
    updateColada(state, colada) {
      state.colada = colada
    },
    updatePaymentOrder(state, payment_order) {
      state.payment_order = payment_order
    },
    updateColaderProfile(state, colader_profile) {
      state.colader_profile = colader_profile
    }
  },
  actions: {
  },
  modules: {
  }
})
