<template>
  <v-select
    v-model="select"
    :items="items"
    class="mx-4"
    flat
    hide-no-data
    hide-details
    label="Select status"
    solo-inverted
  ></v-select>
</template>

<script>
import store from "@/store/index";
export default {
  name: "IdDocumentSearchInput",
  data: () => ({
    descriptionLimit: 60,
    items: ["expired", "none", "pending", "rejected", "valid"],
    model: null,
    select: null,
    search: null,
  }),

  watch: {
    select(val) {
      store.commit("updateIdDocumentSearchStatus", val);
      console.log(val);
    },
  },
};
</script>
