<template>
  <v-container>
    <div class="flex-no-wrap justify-space-between">
      <v-row class="mx-0 my-1">
        <h3 class="secondary--text font-weight-bold">
          {{ colader_profile.delivery_time }}
        </h3>
        <div class="mx-1"></div>

        <h3 class="secondary--text font-weight-bold">
          {{ colader_profile.weight }}
          <v-icon size="25" color="secondary">$kg</v-icon>
        </h3>

        <div class="mx-1"></div>

        <v-icon v-if="colader_profile.ironing" size="25" color="secondary"
          >$iron</v-icon
        >
        <div v-if="colader_profile.ironing" class="mx-1"></div>
        <v-icon
          v-if="colader_profile.drying == 'hang_up'"
          size="25"
          color="secondary"
          >$hang_up</v-icon
        >
        <div v-if="colader_profile.drying == 'hang_up'" class="mx-1"></div>

        <v-icon
          v-if="colader_profile.shipping.indexOf('door_to_door') > -1"
          color="secondary"
          size="25"
          >$truck</v-icon
        >
        <div
          v-if="colader_profile.shipping.indexOf('door_to_door') > -1"
          class="mx-1"
        ></div>
        <v-icon v-if="colader_profile.underwear" size="25" color="secondary"
          >$underwear</v-icon
        >
      </v-row>


      <colader-profile-item
        title="Trainee"
        >
        <template slot="action">

          <v-checkbox
            v-model="trainee"
            color="secondary"
            @change="updateTrainee"
          ></v-checkbox>
        </template>
      </colader-profile-item>



      <colader-profile-item
        title="Employee"
        >
        <template slot="action">

          <v-checkbox
            v-model="employee"
            color="secondary"
            @change="updateEmployee"
          ></v-checkbox>
        </template>
      </colader-profile-item>


      <colader-profile-item
        title="Use courier"
        >
        <template slot="action">

          <v-checkbox
            v-model="use_courier"
            color="secondary"
            @change="updateUseCourier"
          ></v-checkbox>
        </template>
      </colader-profile-item>

      <colader-profile-item
        :title="
          colader_profile.address.street_address +
          ' ' +
          colader_profile.address.street_address_2
        "
        :subtitle="
          colader_profile.address.postal_code +
          ' ' +
          colader_profile.address.city +
          ' ' +
          colader_profile.address.country
        "
      >
      </colader-profile-item>

      <colader-profile-item v-if="isWashNDryEnabled" title="Service Types">
        <template v-slot:action>
          <v-checkbox
            v-model="colader_profile.colada_types"
            label="Wash N Dry"
            color="secondary"
            value="wash_n_dry"
            @change="updateColaderProfileColadaType"

          ></v-checkbox>

          <v-checkbox
            v-model="colader_profile.colada_types"
            label="Ironing Only"
            color="secondary"
            value="ironing_only"
            @change="updateColaderProfileColadaType"
          ></v-checkbox>
        </template>
      </colader-profile-item>

      <colader-profile-item
        v-if="isWashNDryEnabled"
        title="Wash n Dry Base price"
        :subtitle="
          colader_profile.price_breakdown.base.amount +
          ' ' +
          colader_profile.price_breakdown.base.currency
        "
      >
        <v-slider
          v-model="colader_profile.price_breakdown.base.amount"
          track-color="grey"
          always-dirty
          min="1"
          max="20"
          step="0.25"
          @end="updateColaderProfile"
        ></v-slider>
      </colader-profile-item>

      <colader-profile-item
        v-if="
          isIroningOnlyEnabled &&
          colader_profile.price_breakdown.base_ironing_only_per_weight_unit
        "
        title="Ironing Only Base Price Per Weight Unit"
        :subtitle="
          colader_profile.price_breakdown.base_ironing_only_per_weight_unit
            .amount +
          ' ' +
          colader_profile.price_breakdown.base_ironing_only_per_weight_unit
            .currency
        "
      >
        <v-slider
          v-model="
            colader_profile.price_breakdown.base_ironing_only_per_weight_unit
              .amount
          "
          track-color="grey"
          always-dirty
          min="1"
          max="20"
          step="0.25"
          @end="updateColaderProfile"
        ></v-slider>
      </colader-profile-item>


      <colader-profile-item v-if="colader_profile.shipping.indexOf('door_to_door') > -1"
       title=" Door to door price"
       :subtitle="colader_profile.price_breakdown.extras.door_to_door.amount + ' ' + 
       colader_profile.price_breakdown.extras.door_to_door.currency"
       >

          <v-slider
            v-model="colader_profile.price_breakdown.extras.door_to_door.amount"
            track-color="grey"
            always-dirty
            min="0"
            max="5"
            step="0.25"
            @end="updateColaderProfile"
          ></v-slider>

        <template v-slot:action>
          <DoorToDoorMap :colader_profile="colader_profile" />

        </template>
      </colader-profile-item>

      <colader-profile-item
        v-if="isWashNDryEnabled"
        title="Extra Ironing price"
        :subtitle="
          colader_profile.price_breakdown.extras.ironing.amount +
          ' ' +
          colader_profile.price_breakdown.extras.ironing.currency
        "
      >
        <v-slider
          v-model="colader_profile.price_breakdown.extras.ironing.amount"
          track-color="grey"
          always-dirty
          min="1"
          max="20"
          step="0.25"
          @end="updateColaderProfile"
        >
        </v-slider>
      </colader-profile-item>

      <colader-profile-item v-if="isWashNDryEnabled" title="Wash N Dry per day">
        <v-text-field
          v-model="colader_profile.wash_n_drys_per_day"
          type="number"
          @input="updateColaderProfile"
        >
        </v-text-field>
      </colader-profile-item>

      <colader-profile-item
        title="Ironing only per days"
        v-if="isIroningOnlyEnabled && colader_profile.ironing_onlys_per_day"
      >
        <v-text-field
          v-model="colader_profile.ironing_onlys_per_day"
          type="number"
          @input="updateColaderProfile"
        >
        </v-text-field>
      </colader-profile-item>

      <colader-profile-item
        title="Min Weight Ironing Only"
        v-if="isIroningOnlyEnabled && colader_profile.min_weight_ironing_only"
      >
        <v-text-field
          v-model="colader_profile.min_weight_ironing_only"
          type="number"
          @input="updateColaderProfile"
        >
        </v-text-field>
      </colader-profile-item>

      <colader-profile-item
        title="Max Weight Ironing Only"
        v-if="isIroningOnlyEnabled && colader_profile.max_weight_ironing_only"
      >
        <v-text-field
          v-model="colader_profile.max_weight_ironing_only"
          type="number"
          @input="updateColaderProfile"
        >
        </v-text-field>
      </colader-profile-item>

      <colader-profile-item title="Working from">
        <calendar-picker
          v-model="colader_profile.working_from"
          v-on:change="updateColaderProfile"
        />
      </colader-profile-item>

      <colader-profile-item title="Working unti">
        <CalendarPicker
          v-model="colader_profile.working_until"
          v-on:change="updateColaderProfile"
        />
      </colader-profile-item>

      <colader-profile-item
        title="Working timezone"
        :subtitle="colader_profile.working_timezone"
      >
      </colader-profile-item>

      <v-list-group :value="false" no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Working Schedule</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="day in day_of_week_schedule" :key="day.day" link>
          <v-list-item-action>
            <v-checkbox
              v-model="day.working"
              color="secondary"
              @change="updateDayOfWeekSchedule"
            ></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="day.day_of_week"></v-list-item-title>

            <v-list-item-subtitle
              :key="schedule.start + day.day_of_week"
              v-for="schedule in workingScheduleSummary(day.working_schedule)"
            >
              {{ schedule.start }} -
              {{ schedule.end_exclusive }}</v-list-item-subtitle
            >
          </v-list-item-content>

          <v-list-item-action>
            <ColadersScheduleSelector
              :day="day"
              v-on:close="updateDayOfWeekSchedule"
            />
          </v-list-item-action>
        </v-list-item>
      </v-list-group>


            <colader-profile-item
        title="Calendar"
        >
        <template slot="action">

          <ColaderCalendar :colader_profile="colader_profile" />

        </template>
      </colader-profile-item>


            <colader-profile-item
        title="Show me as colader"
        >
        <template slot="action">

          <v-checkbox
            v-model="show_me_as_colader"
            color="secondary"
            @change="updateShowMeAsColader"
          ></v-checkbox>
        </template>
      </colader-profile-item>

    </div>
  </v-container>
</template>

<script>
import HTTP from "@/api/HTTP";

import DoorToDoorMap from "@/components/DoorToDoorMap";

import ColadersScheduleSelector from "@/components/coladers/ColadersScheduleSelector";
import ColaderCalendar from "@/components/coladers/ColaderCalendar";
import ColaderProfileItem from "@/components/coladers/ColaderProfileItem";

import { calculateWorkingScheduleSummary } from "@/js/common.js";
import CalendarPicker from "@/components/utils/CalendarPicker";

export default {
  name: "ColaderProfileInfo",
  props: ["colader_profile"],

  data() {
    return this.initialState();
  },
  components: {
    DoorToDoorMap,
    ColadersScheduleSelector,
    ColaderCalendar,
    CalendarPicker,
    ColaderProfileItem,
  },
  computed: {
    isIroningOnlyEnabled() {
      return this.colader_profile.colada_types.indexOf("ironing_only") > -1;
    },

    isWashNDryEnabled() {
      return this.colader_profile.colada_types.indexOf("wash_n_dry") > -1;
    },
  },

  created() {
    this.getEmployee();
    this.getTrainee();
    this.getUseCourier();
    this.getShowMeAsColader();

    this.getDayOfWeekSchedule();
  },
  methods: {
    initialState() {
      return {
        employee: undefined,
        trainee: undefined,
        use_courier: undefined,
        show_me_as_colader: undefined,
        day_of_week_schedule: undefined,
      };
    },

    clear() {
      Object.assign(this.$data, this.initialState());
    },

    workingScheduleSummary: calculateWorkingScheduleSummary,
    getDayOfWeekSchedule() {
      if (this.colader_profile.id) {
        HTTP.get(
          "coladas/coladers/" +
            this.colader_profile.id +
            "/day-of-week-working-schedules"
        )
          .then((response) => {
            this.day_of_week_schedule = response.data;
          })
          .catch(() => {
            this.day_of_week_schedule = undefined;
          });
      }
    },

    updateColaderProfileColadaType() {
      if (this.isIroningOnlyEnabled) {
        if (
          this.colader_profile.price_breakdown
            .base_ironing_only_per_weight_unit == null
        ) {
          this.colader_profile.price_breakdown.base_ironing_only_per_weight_unit =
            {
              amount: 4,
              currency: "EUR",
            };
        }

        if (this.colader_profile.ironing_onlys_per_day == null) {
          this.colader_profile.ironing_onlys_per_day = 1;
        }

        if (this.colader_profile.delivery_time_ironing_only == null) {
          this.colader_profile.delivery_time_ironing_only = "24h";
        }

        if (this.colader_profile.min_weight_ironing_only == null) {
          this.colader_profile.min_weight_ironing_only = 4;
        }

        if (this.colader_profile.max_weight_ironing_only == null) {
          this.colader_profile.max_weight_ironing_only = 4;
        }
      }

      this.updateColaderProfile();
    },

    updateColaderProfile() {
      if (this.colader_profile.id) {
        HTTP.put(
          "coladas/coladers/" + this.colader_profile.id,
          this.colader_profile
        ).then((response) => {
          this.colader_profile = response.data;
        });
      }
    },

    updateDayOfWeekSchedule() {
      if (this.colader_profile.id) {
        HTTP.put(
          "coladas/coladers/" +
            this.colader_profile.id +
            "/day-of-week-working-schedules",
          this.day_of_week_schedule
        )
          .then(() => {
            //this.day_of_week_schedule = response.data;
          })
          .catch(() => {
            this.day_of_week_schedule = undefined;
          });
      }
    },

    getTrainee() {
      if (this.colader_profile.id) {
        HTTP.get(
          "coladas/admin/coladers/" + this.colader_profile.id + "/trainee"
        )
          .then((response) => {
            this.trainee = response.data.trainee;
          })
          .catch(() => {
            this.trainee = undefined;
          });
      }
    },

    getEmployee() {
      if (this.colader_profile.id) {
        HTTP.get(
          "coladas/admin/coladers/" + this.colader_profile.id + "/employee"
        )
          .then((response) => {
            this.employee = response.data.employee;
          })
          .catch(() => {
            this.employee = undefined;
          });
      }
    },
    getUseCourier() {
      if (this.colader_profile.id) {
        HTTP.get(
          "coladas/admin/coladers/" + this.colader_profile.id + "/use-courier"
        )
          .then((response) => {
            this.use_courier = response.data.use_courier;
          })
          .catch(() => {
            this.use_courier = undefined;
          });
      }
    },

    getShowMeAsColader() {
      if (this.colader_profile.id) {
        HTTP.get(
          "coladas/coladers/" + this.colader_profile.id + "/show_me_as_colader"
        )
          .then((response) => {
            this.show_me_as_colader = response.data.show_me_as_colader;
          })
          .catch(() => {
            this.show_me_as_colader = undefined;
          });
      }
    },

    updateShowMeAsColader() {
      if (this.colader_profile.id) {
        HTTP.put(
          "coladas/coladers/" + this.colader_profile.id + "/show_me_as_colader",
          {
            show_me_as_colader: this.show_me_as_colader,
          }
        ).then((response) => {
          this.show_me_as_colader = response.data.show_me_as_colader;
        });
      }
    },

    updateUseCourier() {
      if (this.colader_profile.id) {
        HTTP.put(
          "coladas/admin/coladers/" + this.colader_profile.id + "/use-courier",
          {
            use_courier: this.use_courier,
          }
        ).then((response) => {
          this.use_courier = response.data.use_courier;
        });
      }
    },

    updateTrainee() {
      if (this.colader_profile.id) {
        HTTP.put(
          "coladas/admin/coladers/" + this.colader_profile.id + "/trainee",
          {
            trainee: this.trainee,
          }
        ).then((response) => {
          this.trainee = response.data.trainee;
        });
      }
    },

    updateEmployee() {
      if (this.colader_profile.id) {
        HTTP.put(
          "coladas/admin/coladers/" + this.colader_profile.id + "/employee",
          {
            employee: this.employee,
          }
        ).then((response) => {
          this.employee = response.data.employee;
        });
      }
    },
  },
};
</script>