<template>
  <v-menu
    ref="date_of_birth_menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        ref="date_of_birth"
        v-model="date_of_birth"
        label="Birthday date*"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      ref="date_of_birth_picker"
      v-model="date_of_birth"
      :max="new Date().toISOString().substr(0, 10)"
      min="1950-01-01"
      @change="save"
    ></v-date-picker>
  </v-menu>
</template>


<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
            default: ""
    },
  },
  data: () => ({
    date_of_birth: "",
    date_of_birth_menu: false,
  }),
  watch: {
    date_of_birth_menu(val) {
      val &&
        setTimeout(
          () => (this.$refs.date_of_birth_picker.activePicker = "YEAR")
        );
    },
  },
  mounted() {
    this.date_of_birth = this.value;
  },

  methods: {
    save(date) {
      this.$refs.date_of_birth_menu.save(date);
      this.$emit("input", date);
    },
  },
};
</script>