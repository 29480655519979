<template>
  <v-dialog v-model="refundDialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="value.status == 'captured'  && value.amount.amount > 0"
      class="mx-2" small color="secondary" dark v-bind="attrs" v-on="on">
        Refund
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline"
        >Make a refund for payment order</v-card-title
      >

      <v-card-text>
        <p>ID {{ value.id }}</p>
        <p>
          Amount {{ value.amount.amount }}
          {{ value.amount.currency }}
        </p>

      <v-form ref="form" class="mx-2" lazy-validation>
        <v-select
          v-model="reason"
          :items="reason_list"
          label="Reason"
          outlined
          required
          :rules="rules.reason"
        ></v-select>
        <v-textarea
          v-model="reason_info"
          clearable
          counter
          clear-icon="mdi-close-circle"
          label="Additional info"
          value=""
        ></v-textarea>
</v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="refundDialog = false">
          Close
        </v-btn>
        <v-btn color="primary darken-1" text @click="submit"> Confirm </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import HTTP from "@/api/HTTP";

export default {
  name: "PaymentRefund",
  props: ["value"],

  data: function () {
    return {
      reason_list: ["item_order_cancelled", "promo"],
      refundDialog: false,
      reason: undefined,
      reason_info: undefined,
      rules: {
        reason: [(v) => !!v || "Reason is required"],
      },
    };
  },

  methods: {
    submit() {
      if ( this.$refs.form.validate() && this.value.id) {
        HTTP.post(
          "billing/admin/payment-orders/" + this.value.id + "/refund",
          {
            reason: this.reason,
            reason_info: this.reason_info,
          }
        ).then((response) => {
          this.refundDialog = false;
                    this.$emit("input", response.data);

        });
      }
    },
  },
  components: {},
};
</script>