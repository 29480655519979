<template>
  <v-container fluid>
   <ColadersTable/>
  </v-container>
</template>

<script>

import ColadersTable from "@/components/coladers/ColadersTable";

export default {
  name: "Coladers",
  components: {
    ColadersTable

  },
};

</script>
