<template>
  <svg
    id="truck"
    style="enable-background: new 0 0 32 32"
    version="1.1"
    viewBox="0 0 32 32"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <polyline class="st0" points="2,9 19,9 19,24 10,24 " />
    <circle class="st0" cx="24" cy="24" r="2" />
    <circle class="st0" cx="8" cy="24" r="2" />
    <polyline class="st0" points="19,24 19,13 25,13 29,18 29,24 26,24 " />
    <line class="st0" x1="4" x2="13" y1="13" y2="13" />
    <line class="st0" x1="2" x2="11" y1="17" y2="17" />
    <rect class="st3" height="680" width="536" x="-288" y="-432" />
  </svg>
</template>

<script>
export default {
  name: "TruckIcon",
};
</script>

 <style type="text/css">

      .st0 {
        fill: none;
        stroke: currentColor;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }

      .st1 {
        fill: none;
        stroke: #000000;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
        stroke-dasharray: 3;
      }
      .st2 {
        fill: none;
        stroke: #000000;
        stroke-width: 2;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }
      .st3 {
        fill: none;
      }
    </style>