import axios from 'axios';

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    clientId :  process.env.VUE_APP_API_CLIENT_ID,
    clientSecret: process.env.VUE_APP_API_CLIENT_SECRET
  }
});

export default HTTP;