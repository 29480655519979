<template>
  <v-container fluid>
    <v-row >
    <v-col cols="1">
               <v-btn
              icon
              @click="getColaders"
              color="primary"
            >
              <v-icon>mdi-cached</v-icon>
            </v-btn>
        <v-switch
      v-model="show_door_to_door_area"
      color="primary"
      label="Show door to door area"
    ></v-switch>

            <v-switch
      v-model="show_locations"
            color="primary"

      label="Show locations"
    ></v-switch>


    </v-col>
    <v-col>
    <l-map
      :key="map_key"
      :zoom="zoom"
      :bounds="bounds"
      :center="center"
      :options="mapOptions"
      style="z-index: 1; height: 70vh; width: 100%"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      @update:bounds="boundsUpdate"

      ref="map"
    >
      <l-tile-layer :url="url" :attribution="attribution" />

<div v-if="show_door_to_door_area">
      <l-circle
        v-for="(circle,i) in circle_list"
        :key="circle.id"
        :lat-lng="circle.center"
        :radius="circle.radius"
        :color="colors[i % colors.length]"
        :fillColor="colors[i % colors.length]"

      />

      <l-geo-json
        v-for="(g, i) in geojson_list"
        :key="g.id"
        :geojson="g.geojson"
        :options-style="styleFunction(circle_list.length + i)"
      />
</div>
<div v-if="show_locations">
  <l-marker 
          v-for="marker in marker_list"
        :key="marker.id"
  :lat-lng="marker.center" ></l-marker>
</div>
    </l-map>
    </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { latLng,latLngBounds } from "leaflet";
import { LMap, LTileLayer, LGeoJson, LCircle, LMarker } from "vue2-leaflet";
import HTTP from "@/api/HTTP";

export default {
  name: "ColaderMap",
  components: {
    LMap,
    LTileLayer,
    LCircle,
    LGeoJson,
    LMarker
  },

  data() {
    return {
      map_key: 0,
      show_door_to_door_area: true,
      show_locations: true,
      colader_profiles: [],
      circle_list: [],
      geojson_list: [],
      marker_list: [],
      center: latLng(43.294572, -2.9878682),
      zoom: 13,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 14.5,
      bounds: latLngBounds([
        [43.384838251721405, -2.645645141601563],
        [43.21818530681703, -3.2303237915039067]
      ]),
      currentBounds: this.bounds,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 1,
      },
      colors: ["#7FB7BE","#7D1538","#379634","#2E382E","#BC2C1A"],
    };
  },

  mounted() {
  },
  methods: {
    styleFunction(i) {
      var color = this.colors[i % this.colors.length];
      return () => {
        return {
          color: color,
          opacity: 1,
          fillColor: color,
        };
      };
    },
    onResize() {
      // Workaround from https://github.com/vue-leaflet/Vue2Leaflet/issues/96

      this.componentKey = this.componentKey + 1;
      // this.$refs.map.mapObject.invalidateSize(); // Doesn't work!
      setTimeout(function () {
        window.dispatchEvent(new Event("resize"));
      }, 250);
    },

    getColaders() {
      HTTP
        .request({
          url : "coladas/admin/coladers",
          method: 'get',
          params: {
            nelat : this.currentBounds._northEast.lat,
            nelon: this.currentBounds._northEast.lng,
            swlat: this.currentBounds._southWest.lat,
            swlon : this.currentBounds._southWest.lng,
          }
        })
        .then((response) => {
          this.colader_profiles = response.data;

          this.geojson_list = this.colader_profiles
          .filter((profile) => { return profile.door_to_door_area.properties == null || profile.door_to_door_area.properties.radius == null })
          .map((profile) => {
              return {
                geojson: profile.door_to_door_area,
                id: "geojson" + profile.id,
              };
          });

          this.circle_list = this.colader_profiles
          .filter((profile) => { return profile.door_to_door_area.properties != null && profile.door_to_door_area.properties.radius != null })

          .map((profile) => {
              return {
                radius: profile.door_to_door_area.properties.radius,
                center: latLng(
                  profile.address.latitude,
                  profile.address.longitude
                ),
                id: "circle" + profile.id,
              };
          });

          this.marker_list = this.colader_profiles
          .map((profile) => {
              return {
                center: [
                  profile.address.latitude,
                  profile.address.longitude
                ],
                id: "marker" + profile.id,
              };
          });

        })
        .catch(() => {
          this.colader_profiles = undefined;
        });
    },

    boundsUpdate(bounds) {
      this.currentBounds = bounds
    },


    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
      this.getColaders()
    },
  },
};
</script>
