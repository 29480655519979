<template v-slot>
  <v-card>
    <v-card-title>
      <span class="headline">ID Documents table</span>
    </v-card-title>
    <v-data-table :headers="headers" 
    :items="documents" class="elevation-1" @click:row="onRowClick">
      <template v-slot:[`item.id`]="{ item }">

        <v-btn v-model="item.id" @click="selectUserId(item.id)">{{
          item.id
        }}</v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <IdDocumentActions :id_document="item"/>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import store from "@/store";
import router from "@/router";

import HTTP from "@/api/HTTP";
import IdDocumentActions from "./IdDocumentActions"

export default {
  name: "IdDocumentsTable",
  data: function () {
    return {
      documents: [],
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  components: {
    IdDocumentActions
  },
  computed: {
    status() {
      return store.state.id_document_search_status;
    },
  },
  methods: {
    updateDocuments() {
      HTTP.get(
        "/auth/admin/id-documents?id_document_status=" + this.status
      ).then((response) => {
        const documents = response.data;
        this.documents = documents;
      });
    },
     onRowClick(params) {
       console.log(params)
      store.commit("updateIdDocument", params )

     },
     update(item) {
       console.log("UPDATE" + item)
     },
    selectUserId(user_id) {
      console.log("Selected UserId", user_id)
      store.commit("updateUserId", user_id);
      router.push("users");

    },
  },
  watch: {
    status() {
      this.updateDocuments();
    },
  },
  mounted() {
    if (this.status) {
      this.updateDocuments();
    }
  },

};
</script>