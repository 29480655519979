<template>
  <v-row justify="center">
    <v-dialog v-model="door_to_door_map" width="700">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="onResize()"
        >
          Show Area in Map
        </v-btn>
      </template>
      <l-map
        :key="map_key"
        :zoom="zoom"
        :center="center"
        :options="mapOptions"
        style="z-index: 1; height: 70vh; width: 100%"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
        ref="map"
      >
        <l-tile-layer :url="url" :attribution="attribution" />

        <l-circle
          v-if="geojson.properties != null && geojson.properties.radius > 0"
          :lat-lng="circle_center"
          :radius="geojson.properties.radius"
          :color="marker_color"
        />

        <l-geo-json
          v-if="geojson.properties == null || geojson.properties.radius == null"
          :geojson="geojson"
                  :options-style="styleFunction"
        />
      </l-map>
    </v-dialog>
  </v-row>
</template>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LGeoJson, LCircle } from "vue2-leaflet";

export default {
  name: "DoorToDoorMap",
  props: ["colader_profile"],
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    LCircle,
  },

  data() {
    return {
      map_key: 0,
      door_to_door_map: false,
      marker_color : "#6E9DA0",
      zoom: 13,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 14.5,
      showParagraph: false,
      center: latLng(
        this.colader_profile.address.latitude,
        this.colader_profile.address.longitude
      ),
      geojson: this.colader_profile.door_to_door_area,
      circle_center: this.colader_profile.door_to_door_area.geometry.coordinates.slice().reverse(),
      mapOptions: {
        zoomSnap: 1,
      },
    };
  },
  computed: {

    styleFunction() {
      return () => {
        return {
          color: this.marker_color,
          opacity: 1,
          fillColor: this.marker_color,
        };
      };
    },
  },
  mounted() {
  },
  methods: {
    onResize() {
      // Workaround from https://github.com/vue-leaflet/Vue2Leaflet/issues/96

      this.componentKey = this.componentKey + 1;
      // this.$refs.map.mapObject.invalidateSize(); // Doesn't work!
      setTimeout(function () {
        window.dispatchEvent(new Event("resize"));
      }, 250);
    },

    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },

  },
};
</script>
