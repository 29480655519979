<template>
  <v-dialog v-model="changeStatusDialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
      class="mx-2" small color="secondary" dark v-bind="attrs" v-on="on">
        Change Status
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline"
        >Change Colada status</v-card-title
      >

      <v-card-text>
        <p>ID {{ colada.id }}</p>
        <p>
          Current Status {{ value }}
        </p>

      <v-form ref="form" class="mx-2" lazy-validation>
        <v-select
          v-model="new_status"
          :items="status_list"
          label="Status"
          outlined
          required
          :rules="rules.status"
        ></v-select>

</v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="changeStatusDialog = false">
          Close
        </v-btn>
        <v-btn color="primary darken-1" text @click="submit"> Confirm </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import HTTP from "@/api/HTTP";

export default {
  name: "ColadaChangeStatus",
  props: ["value" , "colada"],

  data: function () {
    return {
      status_list: ["delivered", "drying", 
      "ironing", "order_accepted", "order_cancelled", 
      "order_placed", "order_rejected", "order_withdrawn", 
      "pending_acceptance", "picked_up", "ready", "under_review", "washing"],
      changeStatusDialog: false,
      new_status: undefined,
      rules: {
        status: [(v) => !!v || "Status is required"],
      },
    };
  },

  methods: {
    submit() {
      if ( this.$refs.form.validate() && this.colada.id) {
        HTTP.put(
          "coladas/admin/coladas/" + this.colada.id + "/status",
          {
            status: this.new_status,
          }
        ).then((response) => {
          this.changeStatusDialog = false;
          this.$emit("input", response.data.status);

        });
      }
    },
  },
  components: {},
};
</script>