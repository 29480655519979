<template>
  <v-dialog v-model="cancelDialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="cancellableColada(colada)" class="mx-2" small color="error" dark v-bind="attrs" v-on="on">
        Cancel
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline"
        >Cancel a colada
        </v-card-title
      >

      <v-card-text>
<h4> ID </h4>
<p> {{ colada.id }} </p>


        <h4> Status </h4>
<p> {{ colada.status }} </p>



      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancelDialog = false">
          Close
        </v-btn>
        <v-btn color="error darken-1" text @click="submit"> Confirm </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import HTTP from "@/api/HTTP";

export default {
  name: "ColadaCancel",
  props: ["value", "colada"],

  data: function () {
    return {
      cancelDialog: false,

    };
  },

  methods: {
  cancellableColada (colada) {
    var status = colada.status
    if (colada.state == "done") {
      return false;
    } else if (status == "order_cancelled") {
      return false;
    } else if (status == "delivered") {
      return false;
    } else if (status == "order_rejected") {
      return false;
    } else if (status == "order_withdrawn") {
      return false;
    }
    return true;
  },
    submit() {
      if (this.colada.id) {
        HTTP.put(
          "coladas/admin/coladas/" + this.colada.id + "/status",
          {
            status: "order_cancelled",
          }
        ).then((response) => {
          this.cancelDialog = false;

          this.$emit("input", response.data.status);
        });
      }
    },
  },
  components: {},
};
</script>