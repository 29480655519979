<template>
  <v-container fluid>
    <IdDocumentsTable/>

  </v-container>
</template>

<script>
// @ is an alias to /src
import IdDocumentsTable from "../components/id-documents/IdDocumentsTable";


export default {
  name: "IdDocuments",
  components: {
    IdDocumentsTable
    
  },
};
</script>
