<template>
  <v-card tile class="mx-auto" width="600">
    <v-card-title>
      {{ value.day | moment("dddd, MMMM Do YYYY") }}
    </v-card-title>
    <v-card-subtitle v-if="value.has_rules">
      <v-icon color="warning"> mdi-ruler </v-icon>
      This day has an especific rule
    </v-card-subtitle>
    <v-card-text>
      <v-switch
        v-model="value.working"
        label="Working"
        @change="updateDaySchedule"
      ></v-switch>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle
            :key="schedule.start + value.working_schedule"
            v-for="schedule in workingScheduleSummary(value.working_schedule)"
          >
            {{ schedule.start }} -
            {{ schedule.end_exclusive }}</v-list-item-subtitle
          >
        </v-list-item-content>
        <ColadersScheduleSelector
          :day="value"
          v-on:day-change="updateDaySchedule"
          v-on:close="$emit('day-change')"
        />
      </v-list-item>
      <div class="text-center">
        <v-btn
          v-if="value.has_rules"
          medium
          color="secondary"
          text
          @click="resetDaySchedule"
        >
          Restore to default
        </v-btn>
      </div>
      <div v-if="value.ongoing_coladas.length">
        <v-card-title>Ongoing coladas</v-card-title>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, i) in value.ongoing_coladas" :key="i">
            <ColadaInfo :colada="item" />
          </v-list-item>
        </v-list-item-group>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import HTTP from "@/api/HTTP";

import ColadaInfo from "@/components/coladas/ColadaInfo";

import ColadersScheduleSelector from "@/components/coladers/ColadersScheduleSelector";
import { calculateWorkingScheduleSummary } from "@/js/common.js";

export default {
  name: "ColaderCalendarDay",
  props: ["value", "colader_profile"],
  components: {
    ColadaInfo,
    ColadersScheduleSelector,
  },

  data() {
    return {
      working_schedule_summary: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    updateDaySchedule() {
      if (this.colader_profile.id) {
        HTTP.put(
          "coladas/coladers/" +
            this.colader_profile.id +
            "/day-working-schedules/" +
            this.value.day,
          this.value
        )
          .then(() => {
            this.$emit("day-change");
          })
          .catch(() => {
            this.day_of_week_schedule = undefined;
          });
      }
    },

    resetDaySchedule() {
      if (this.colader_profile.id) {
        HTTP.delete(
          "coladas/coladers/" +
            this.colader_profile.id +
            "/day-working-schedules/" +
            this.value.day
        )
          .then(() => {
            this.$emit("day-change");
          })
          .catch(() => {
            this.day_of_week_schedule = undefined;
          });
      }
    },

    workingScheduleSummary: calculateWorkingScheduleSummary,
  },
};
</script>
